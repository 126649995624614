import { DesktopNav } from "./DesktopNav"
import { MobileNav } from "./MobileNav"
import { Media } from "../../Components/Responsive"

export const Nav: React.FC<{
  nextSlide?: () => void
  previousSlide?: () => void
  currentIndex?: number
  nextDisabled?: boolean
  onShuffle?: () => void
  onClose?: () => void
}> = ({ nextSlide, previousSlide, currentIndex, nextDisabled, onShuffle, onClose }) => {
  return (
    <>
      <Media greaterThan="sm">
        <DesktopNav
          nextSlide={nextSlide}
          previousSlide={previousSlide}
          currentIndex={currentIndex}
          nextDisabled={nextDisabled}
          onShuffle={onShuffle}
        />
      </Media>
      <Media lessThan="md">
        <MobileNav
          onClose={onClose}
          nextSlide={nextSlide}
          previousSlide={previousSlide}
          currentIndex={currentIndex}
          nextDisabled={nextDisabled}
          onShuffle={onShuffle}
        />
      </Media>
    </>
  )
}
