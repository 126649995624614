import React, { useState } from "react"
import { Spacer } from "./Spacer"
import { Sans } from "./Typography"

export const Picture: React.FC<{
  src: string
  alt?: string
  onLoad?: () => void
  imgRef?: any
  title?: string
  width: number
  onClick?: (e: any) => void
}> = ({ src, alt, imgRef, onLoad, width, onClick, title }) => {
  const [hover, setHover] = useState(false)
  const resizedSRC = src + `?w=${width}`
  return (
    <>
      <img
        src={resizedSRC}
        ref={imgRef}
        alt={alt}
        key={src}
        onLoad={onLoad}
        onClick={(e) => onClick?.(e)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      />
      {!!title && hover && (
        <div>
          <Spacer mb={2} />
          <Sans size="0">{title}</Sans>
        </div>
      )}
    </>
  )
}
