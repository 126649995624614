import { Sans } from "../../Components/Typography"
import { Flex } from "../../Components/Flex"
import { useRouter } from "next/dist/client/router"
import styled from "styled-components"
import NextLink from "next/link"
import { color } from "../../helpers/color"
import { space } from "styled-system"
import { Box } from "../Box"

export const DesktopNav: React.FC<{
  nextSlide?: () => void
  previousSlide?: () => void
  currentIndex: number
  nextDisabled: boolean
  onShuffle: () => void
}> = ({ nextSlide, previousSlide, currentIndex, nextDisabled, onShuffle }) => {
  const router = useRouter()

  const backDisabled = currentIndex === 0

  const onClickNext = () => {
    if (!nextDisabled) {
      nextSlide()
    }
  }
  const onClickBack = () => {
    if (!backDisabled) {
      previousSlide()
    }
  }

  return (
    <NavWrapper justifyContent="space-between" width="100%" px={2} pb="12px" style={{ zIndex: 50 }}>
      <Box width="573px">
        <NextLink href="/">
          <Sans size="1" style={{ cursor: "pointer" }}>
            DANIEL GAINES / STYLIST
          </Sans>
        </NextLink>
      </Box>
      {!onShuffle && (
        <Box px={2}>
          {nextSlide && previousSlide && (
            <Sans size="1">
              <NextPreviousButtons disabled={backDisabled} onClick={onClickBack}>
                BACK
              </NextPreviousButtons>
              <span style={{ color: "black" }}> / </span>
              <NextPreviousButtons disabled={nextDisabled} onClick={onClickNext}>
                NEXT
              </NextPreviousButtons>
            </Sans>
          )}
        </Box>
      )}
      {!!onShuffle && (
        <Box px={2}>
          <Sans size="1" onClick={onShuffle} style={{ cursor: "pointer" }}>
            SHUFFLE
          </Sans>
        </Box>
      )}
      <Sans size="1">
        <NextLink href="/overview">
          <LinkText active={!!router.pathname.match(/^\/overview/)}>OVERVIEW </LinkText>
        </NextLink>
        <span style={{ color: "black" }}> / </span>
        <NextLink href="/sketchbook">
          <LinkText active={!!router.pathname.match(/^\/sketchbook/)}> SKETCHBOOK </LinkText>
        </NextLink>
        <span style={{ color: "black" }}> / </span>
        <NextLink href="/info">
          <LinkText active={!!router.pathname.match(/^\/info/)}> INFO</LinkText>
        </NextLink>
      </Sans>
    </NavWrapper>
  )
}

const NavWrapper = styled(Flex)`
  span:hover {
    color: ${color("gray")};
  }
  p:hover {
    color: ${color("gray")};
  }
`

const LinkText = styled("span")<{ active?: boolean }>`
  color: ${(p) => (p.active ? color("gray") : "black")};
  text-decoration: none;
  cursor: pointer;
`

const NextPreviousButtons = styled("span")<{ disabled: boolean }>`
  color: ${(p) => (p.disabled ? color("gray") : "black")};
  padding-bottom: ${space(2)}px;
  cursor: ${(p) => (p.disabled ? "auto" : "pointer")};
`
