import { Theme } from "../lib/theme"
import { Flex } from "./Flex"
import { Nav } from "./Nav"

export const Layout: React.FC<{
  children: any
  nextSlide?: () => void
  previousSlide?: () => void
  currentIndex?: number
  nextDisabled?: boolean
  onShuffle?: () => void
}> = ({ children, nextSlide, previousSlide, currentIndex, nextDisabled, onShuffle }) => {
  return (
    <Theme>
      <Flex flexDirection="column" style={{ flex: 1 }} justifyContent="space-between" height="100%" width="100%">
        {children}
        <Nav
          nextSlide={nextSlide}
          previousSlide={previousSlide}
          currentIndex={currentIndex}
          nextDisabled={nextDisabled}
          onShuffle={onShuffle}
        />
      </Flex>
    </Theme>
  )
}
