import { Sans } from "../../Components/Typography"
import { Flex, Box } from "../../Components"
import { useRouter } from "next/dist/client/router"
import styled from "styled-components"
import NextLink from "next/link"
import { color } from "../../helpers/color"
import { space } from "styled-system"

export const MobileNav: React.FC<{
  nextSlide?: () => void
  previousSlide?: () => void
  currentIndex: number
  nextDisabled: boolean
  onShuffle: () => void
  onClose?: () => void
}> = ({ nextSlide, previousSlide, currentIndex, nextDisabled, onShuffle, onClose }) => {
  const router = useRouter()

  const backDisabled = currentIndex === 0

  const onClickNext = () => {
    if (!nextDisabled) {
      nextSlide()
    }
  }
  const onClickBack = () => {
    if (!backDisabled) {
      previousSlide()
    }
  }

  return (
    <>
      <Header>
        {onClose ? (
          <Box px={2} onClick={onClose}>
            <Sans size="3">Close</Sans>
          </Box>
        ) : (
          <NextLink href="/">
            <Sans size="3" style={{ cursor: "pointer" }} color="black">
              DANIEL GAINES / STYLIST
            </Sans>
          </NextLink>
        )}
      </Header>

      <Footer>
        {nextSlide && previousSlide ? (
          <Sans size="3" color="black">
            <NextPreviousButtons disabled={backDisabled} onClick={onClickBack}>
              BACK
            </NextPreviousButtons>
            <span style={{ color: "black" }}> / </span>
            <NextPreviousButtons disabled={nextDisabled} onClick={onClickNext}>
              NEXT
            </NextPreviousButtons>
          </Sans>
        ) : (
          <Sans size="3" color="black">
            <NextLink href="/overview">
              <LinkText active={!!router.pathname.match(/^\/overview/)}>OVERVIEW </LinkText>
            </NextLink>
            <span style={{ color: "black" }}> / </span>
            <NextLink href="/sketchbook">
              <LinkText active={!!router.pathname.match(/^\/sketchbook/)}> SKETCHBOOK </LinkText>
            </NextLink>
            <span style={{ color: "black" }}> / </span>
            <NextLink href="/info">
              <LinkText active={!!router.pathname.match(/^\/info/)}> INFO</LinkText>
            </NextLink>
          </Sans>
        )}
      </Footer>
    </>
  )
}

const Header = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 2000;
  display: flex;
  padding-top: 14px;
  padding-bottom: 11px;
  flex-direction: row;
  justify-content: center;
`

const Footer = styled("div")`
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 2000;
  padding-top: 15px;
  padding-bottom: 11px;
  padding-right: 16px;
  padding-left: 16px;
  flex-direction: row;
  justify-content: center;
`

const LinkText = styled("span")<{ active?: boolean }>`
  color: ${(p) => (p.active ? color("gray") : "black")};
  text-decoration: none;
  cursor: pointer;
`

const NextPreviousButtons = styled("span")<{ disabled: boolean }>`
  color: ${(p) => (p.disabled ? color("gray") : "black")};
  padding-bottom: ${space(2)}px;
  cursor: ${(p) => (p.disabled ? "auto" : "pointer")};
`
